.draggable-item[data-v-83dd77e4] {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05), 0px 10px 20px rgba(0, 0, 0, 0.1);
}
.draggable-item .content[data-v-83dd77e4] {
  flex: 1 1 auto;
}
