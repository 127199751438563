.placeholder[data-v-d1487784] {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
}
.tools[data-v-d1487784] {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}
.tools .drag-handle[data-v-d1487784] {
  cursor: grab;
}
