.toggle-btn[data-v-d532ab78] {
  display: flex;
  width: 20px;
  height: 20px;
  cursor: pointer;
  --icon-stroke: var(--gray-500);
}
.toggle-btn .sidebar-toggle-placeholder[data-v-d532ab78] {
  transition: all 0.5s ease-in-out;
}
.toggle-btn .sidebar-toggle-icon[data-v-d532ab78] {
  transition: all 0.5s ease-in-out;
  display: none;
}
.toggle-btn:hover .sidebar-toggle-placeholder[data-v-d532ab78] {
  display: none;
}
.toggle-btn:hover .sidebar-toggle-icon[data-v-d532ab78] {
  display: block;
}
