.directory-tree[data-v-a0790da3] {
  flex: 1;
  width: 100%;
  min-width: 1px;
}
.custom-tree-node-label[data-v-a0790da3] {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
